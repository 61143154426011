.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: white;
  }
  
  .hover-underline-animation:after {
    content: '';
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }