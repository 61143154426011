.Modal {
    display: flex;
    /* transition: all 0.5s ease-out; */
}

.ModalOpen {
    animation: openModal 0.5s ease-out forwards;
}
.ModalClosed {
    animation: closeModal 0.5s ease-out forwards;
}

@keyframes openModal {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes closeModal {
    0% { opacity: 1; }
    100% { opacity: 0;}
}